// import axios from 'axios';
import {
  // salesorder,
  getflete,
  accounttype,
  vtigerpayment,
  viaspago,
  estatus,
  // updateListaPrecio,
  // cnr,
} from '../../config/api/salesorder';

import { usercnr } from '../../config/api/usuarios';

export default {

  name: 'salesorder',
  namespaced: true,
  state: {
    // PRINCIPAL
    items: [],
    // FORMULARIO
    show: false,
    show1: false,
    showflete: false,
    show2: false,
    isAdd: false,
    isUpdate: false,
    isShow: false,
    close: false,
    open: false,
    title: '',
    // DIALOG
    dialogAccount: false,
    dialogProduct: false,
    // TEXT
    esporadicoT: true,
    esporadicoF: false,
    nrosap: null,
    // COMBOBOX

    account: [], // tipo cliente
    accountType: null, // tipo cliente

    salesorder: [], // tipo de pedido
    type: null, // tipo de pedido

    traspo: [],
    transporte: null,

    regiones: [],
    region: null,

    payment: [],
    cobro: null,

    porcentaje: [],
    flete: null,
    des: 1,
    mot: 0.00,
    totalFlete: 0.00, // ser guarda el Subtotal

    newflete: '',

    descuento: null,

    executives: [], // user cnr
    executive: null,
    ejecutivo: null,

    sucursal: null,

    viaspago: [],
    vp: null,

    // listprice: [],
    listprice: [],
    listaPrecio: null,

    // Via_pago: [], //ojo borrar
    cond_venta: null, // ojo prueba
    cond_compra: null, // ojo borrar

    estatus: [],
    // FIN

    cliente_esporadico: '',

    // id de salesorde
    salesorderid: '',
    salesorder_no: '',
    notas: '',
    description: '',
    poblacion: '',
    codigo_postal: '',
    tasaIndicada: '',

    cart: [],
    v: [],
    peso: 0.0,
    form: {
      percent: 0.0,
      freight: 0.0,
      discount: 0.0,
      subtotal: 0.0,
      iva: 0.0,
      total: 0.0,
      sostatus: 'Creado',
    },
    verflete: 1,
    ivachek: null,
    porc: false,
  },
  mutations: {
    // PRINCIPAL
    SET_DATA(state, payload) {
      state.items = payload.data;
    },
    // FORMULARIO
    SHOW(state) {
      state.show = !state.show;
    },
    SHOW1(state) {
      state.show1 = !state.show1;
    },
    SHOW2(state) {
      state.show2 = !state.show2;
    },
    SHOWFlete(state) {
      state.showflete = !state.showflete;
    },
    SET_IS_ADD(state, payload) {
      state.isAdd = payload;
    },
    SET_IS_UPDATE(state, payload) {
      state.isUpdate = payload;
    },
    SET_IS_SHOW(state, payload) {
      state.isShow = payload;
    },
    SET_TITLE(state, payload) {
      state.title = payload;
    },
    SET_VERFLETE(state, payload) {
      state.des = payload;
    },

    MOT(state, payload) {
      state.mot = payload;
    },

    TOTALFLETE(state, payload) {
      state.totalFlete = payload;
    },

    SET_IS_CLOSE(state, payload) {
      state.close = payload;
    },
    SET_IS_OPEN(state, payload) {
      state.open = payload;
    },
    // COMBOBOX

    SET_DATA_ACCOUNT(state, parameter) { // tipo cliente
      state.account = parameter.data;
    },

    SET_DATA_VIASPAGO(state, parameter) {
      state.viaspago = parameter.data; // arreglo via de pago
    },

    SET_DATA_SALESORDER(state, parameter) { // tipo de pedido
      state.salesorder = parameter.data; //  arreglo
    },

    SET_DATA_PAYMENT(state, parameter) {
      state.payment = parameter.data;
    },

    SET_DATA_FLETE(state, parameter) {
      state.flete = parameter.data;
    },

    SET_DATA_CONDICION(state, parameter) {
      state.listprice = parameter.data;
    },

    SET_FLETE(state, parameter) {
      state.flete = parameter;
    },

    SET_IVACHECK(state, parameter) {
      state.ivachek = parameter;
    },

    SET_PORC(state, parameter) {
      state.porc = parameter;
    },

    SET_DATA_DESCUENTO(state, parameter) {
      state.descuento = parameter.data;
    },

    SET_DESCUENTO(state, parameter) {
      state.descuento = parameter;
    },

    SET_CONDICION(state, parameter) {
      state.listaPrecio = parameter;
    },

    SET_EJECUTIVO(state, parameter) { // user cnr
      state.executive = parameter;
    },

    SET_EXECUTIVE(state, parameter) { // user cnr
      state.ejecutivo = parameter;
    },

    SET_ACCOUNT(state, parameter) { // tipo cliente
      state.accountType = parameter;
    },

    SET_SUCURSAL(state, parameter) { // sucursal
      state.sucursal = parameter;
    },

    SET_VIASPAGO(state, parameter) {
      state.vp = parameter;
    },

    SET_SALESORDER(state, parameter) { // tipo de pedido
      state.type = parameter;
      console.log(state.type);
    },

    SET_TRANSPORTE(state, parameter) {
      state.transporte = parameter; // funciona
    },

    SET_REGION(state, parameter) {
      state.region = parameter; // funciona
    },

    SET_COND_COMPRA(state, parameter) {
      state.cond_compra = parameter; // funciona
    },

    SET_NOTA(state, parameter) {
      state.notas = parameter; // funciona
    },

    SET_DESCRIPTION(state, parameter) {
      state.description = parameter; // funciona
    },

    SET_NEWFLETE(state, parameter) {
      state.newflete = parameter; // funciona
    },

    SET_POBLACION(state, parameter) {
      state.poblacion = parameter; // funciona
    },

    SET_CODIGO(state, parameter) {
      state.codigo_postal = parameter; // funciona
    },

    SET_NR_SAP(state, parameter) {
      state.nrosap = parameter; // funciona
    },

    SET_DATA_ESTATUS(state, parameter) {
      state.estatus = parameter.data;
    },

    SET_DATA_USERCNR(state, parameter) {
      state.executives = parameter.data;
    },

    SET_DATA_REGION(state, parameter) {
      state.regiones = parameter.data;
      console.log(parameter.data);
    },

    SET_DATA_CLIENTE_ESPORADICO(state, parameter) {
      state.cliente_esporadico = parameter;
    },
    // DIALOG
    SET_DIALOG_ACCOUNT(state) {
      state.dialogAccount = !state.dialogAccount;
    },
    SET_DIALOG_PRODUCT(state) {
      state.dialogProduct = !state.dialogProduct;
    },
    SET_CART(state, payload) {
      state.cart = payload;
      console.log(payload);
    },
    SET_FREIGHT(state, payload) {
      state.form.freight = payload;
    },
    SET_DISCOUNT(state, payload) {
      state.form.discount = payload;
    },
    SET_SUBTOTAL(state, payload) {
      state.form.subtotal = payload;
    },
    SET_IVA(state, payload) {
      state.form.iva = payload;
    },
    SET_TOTAL(state, payload) {
      state.form.total = payload;
    },
    SET_FORM(state, parameter) {
      state.form = parameter;
      console.log(parameter);
    },
    SET_PESO(state, payload) {
      state.form.peso = payload;
    },

    // prueba
    SET_DATA_ID(state, parameter) {
      state.salesorderid = parameter.data;
    },
    // CAMPOS DE FORMULARIO
    SET_ID(state, parameter) {
      console.log(parameter);
      state.salesorderid = parameter;
    },
    SET_TASA(state, parameter) {
      state.tasaIndicada = parameter;
    },

    SET_DATA_SALESORDER_NO(state, parameter) {
      state.salesorder_no = parameter.data;
    },
    // CAMPOS DE FORMULARIO
    SET_SALESORDER_NO(state, parameter) {
      state.salesorder_no = parameter;
    },

  },
  actions: {
    // PRINCIPAL
    async data({ commit }, parameter) {
      commit('SET_DATA', await getflete(parameter));
    },
    add({ commit }) {
      commit('SHOW');
      commit('SET_TITLE', 'AGREGAR');
      commit('SET_IS_ADD', true);
      commit('SET_IS_UPDATE', false);
      commit('SET_IS_SHOW', false);
      commit('SET_IS_CLOSE', true);// nuevo
    },
    clean({ commit }) {
      commit('SHOW');
      commit('SET_IS_ADD', false);
      commit('SET_IS_UPDATE', false);
      commit('SET_IS_SHOW', false);
      commit('SET_IS_CLOSE', false);// nuevo
      const form = {
        percent: 0.0,
        freight: 0.0,
        subtotal: 0.0,
        iva: 0.0,
        total: 0.0,
        sostatus: 'Creado',
      };
      this.esporadicoT = true;
      this.esporadicoF = false;
      commit('SET_FORM', form);
      commit('SET_CART', []);
      commit('SET_ID', '');
      commit('SET_SALESORDER_NO', '');
      commit('SET_ACCOUNT', '');
      commit('SET_SALESORDER', '');
      commit('SET_TRANSPORTE', '');
      commit('SET_REGION', '');
      commit('SET_COND_COMPRA', '');
      commit('SET_VIASPAGO', '');
      commit('SET_NOTA', '');
      commit('SET_DESCRIPTION', '');
      commit('SET_CODIGO', '');
      commit('SET_POBLACION', '');
      commit('SET_NR_SAP', '');
      commit('SET_FLETE', '');
      commit('SET_DESCUENTO', '');
      commit('SET_EJECUTIVO', '');
      commit('SET_SUCURSAL', '');
      commit('SET_EXECUTIVE', '');// para peru
      commit('SET_DATA_CLIENTE_ESPORADICO', '');
    },
    reset({ commit }) {
      commit('SHOW');
      commit('SET_IS_ADD', false);
      commit('SET_IS_UPDATE', false);
      commit('SET_IS_SHOW', false);
      const form = {
        percent: 0.0,
        freight: 0.0,
        subtotal: 0.0,
        iva: 0.0,
        total: 0.0,
        sostatus: 'Creado',
      };
      this.esporadicoT = true;
      this.esporadicoF = false;
      commit('SET_FORM', form);
      commit('SET_CART', []);
      commit('SET_SALESORDER_NO', '');
      commit('SET_ACCOUNT', '');
      commit('SET_SALESORDER', '');
      commit('SET_TRANSPORTE', '');
      commit('SET_REGION', '');
      commit('SET_COND_COMPRA', '');
      commit('SET_VIASPAGO', '');
      commit('SET_NOTA', '');
      commit('SET_DESCRIPTION', '');
      commit('SET_POBLACION', '');
      commit('SET_CODIGO', '');
      commit('SET_NR_SAP', '');
      commit('SET_FLETE', '');
      commit('SET_DESCUENTO', '');
      commit('SET_EJECUTIVO', '');
      commit('SET_SUCURSAL', '');
      commit('SET_EXECUTIVE', '');// para peru
      commit('SET_DATA_CLIENTE_ESPORADICO', '');
    },
    update({ commit }, parameter) {
      commit('SHOW');
      commit('SET_TITLE', 'EDITAR');
      commit('SET_IS_CLOSE', true);// nuevo
      commit('SET_IS_OPEN', false);// nuevo
      commit('SET_IS_UPDATE', true);
      commit('SET_IS_ADD', false);
      commit('SET_IS_SHOW', false);
      commit('SET_ID', parameter.salesorderid);
      commit('SET_SALESORDER_NO', parameter.salesorder_no);
      commit('SET_CART', parameter.items);
      commit('SET_FORM', parameter);
      commit('SET_ACCOUNT', parameter.accountType); // tipo cliente
      commit('SET_VIASPAGO', parameter.vp);
      commit('SET_FLETE', parameter.flete);
      commit('SET_DESCUENTO', parameter.descuento);
      commit('SET_SALESORDER', parameter.type); // tipo de pedido
      commit('SET_TRANSPORTE', parameter.transporte);
      commit('SET_REGION', parameter.region);
      commit('SET_COND_COMPRA', parameter.cond_compra);
      commit('SET_NOTA', parameter.notas);
      commit('SET_DESCRIPTION', parameter.description);
      commit('SET_POBLACION', parameter.poblacion);
      commit('SET_CODIGO', parameter.codigo_postal);
      commit('SET_NR_SAP', parameter.nrosap);
      commit('SET_EJECUTIVO', parameter.ejecutivo);
      commit('SET_EXECUTIVE', parameter.executive);// para peru
      commit('SET_SUCURSAL', parameter.sucursal);
      commit('SET_DATA_CLIENTE_ESPORADICO', parameter.cliente_esporadico);
      commit('SET_IVACHECK', parameter.ivachek);
      commit('SET_PORC', parameter.porc);
    },
    show({ commit }, payload) {
      commit('SHOW');
      commit('SET_IS_UPDATE', false);
      commit('SET_IS_ADD', false);
      commit('SET_IS_SHOW', true);
      commit('SET_IS_OPEN', true);// nuevo
      commit('SET_IS_CLOSE', true);// nuevo
      commit('SET_TITLE', 'VISUALIZAR');
      commit('SET_CART', payload.items);
      commit('SET_PESO', payload.items);
      commit('SET_FORM', payload);
      commit('SET_ID', payload.salesorderid);
      commit('SET_SALESORDER_NO', payload.salesorder_no);
      commit('SET_ACCOUNT', payload.accountType); // tipo cliente
      commit('SET_VIASPAGO', payload.vp);
      commit('SET_FLETE', payload.flete);
      commit('SET_DESCUENTO', payload.descuento);
      commit('SET_SALESORDER', payload.type); // tipo de pedido
      commit('SET_TRANSPORTE', payload.transporte);
      commit('SET_REGION', payload.region);
      commit('SET_COND_COMPRA', payload.cond_compra);
      commit('SET_NOTA', payload.notas);
      commit('SET_DESCRIPTION', payload.description);
      commit('SET_POBLACION', payload.poblacion);
      commit('SET_CODIGO', payload.codigo_postal);
      commit('SET_NR_SAP', payload.nrosap);
      commit('SET_EJECUTIVO', payload.ejecutivo);
      commit('SET_SUCURSAL', payload.sucursal);
      commit('SET_DATA_CLIENTE_ESPORADICO', payload.cliente_esporadico);
      commit('SET_FREIGHT', payload.items[0].weight);
      commit('SET_EXECUTIVE', payload.executive);// para Peru
      commit('SET_IVACHECK', payload.ivachek);
      commit('SET_PORC', payload.porc);
    },
    showTasa({ commit }, payload) {
      commit('SHOW1');
      commit('SET_TITLE', 'Tasa del Dia');
      commit('SET_ID', payload.salesorderid);
      commit('SET_TASA', payload.tasaIndicada);
      commit('SET_CART', payload.items);
      commit('SET_SALESORDER', payload.type); // tipo de pedido
      commit('SET_FORM', payload);
    },
    showFlete({ commit }, payload) {
      console.log(payload);
      commit('SHOWFlete');
      commit('SET_VERFLETE', payload.des);
      commit('MOT', payload.mot);
      commit('SET_TITLE', 'Fletezee');
      /* eslint-disable-next-line eqeqeq */
      commit('SET_SALESORDER_NO', payload.item.salesorder_no == undefined ? payload.pdd : payload.item.salesorder_no);
      commit('SET_FLETE', payload.item.flete);
      /* eslint-disable-next-line eqeqeq */
      commit('SET_ID', payload.item.salesorderid == undefined ? payload.ids : payload.item.salesorderid);
      commit('SET_CART', payload.item.items);
      commit('SET_FORM', payload.item);
      commit('TOTALFLETE', payload.totalFlete); // se guarda el subtotal del pedido
    },
    show2({ commit }, payload) {
      commit('SET_DATA', payload.items);
      commit('SHOW2');
    },
    cierre({ commit }) {
      commit('SHOW2', false);
    },
    cerrar({ commit }) {
      commit('SHOW1', false);
      commit('SET_TASA', '');
    },
    cerrarFlete({ commit }) {
      commit('SHOWFlete', false);
    },
    async initializingForm({ commit }, parameter) {
      commit('SET_DATA_ACCOUNT', await accounttype(parameter)); // tipo cliente
      commit('SET_DATA_PAYMENT', await vtigerpayment(parameter));
      commit('SET_DATA_VIASPAGO', await viaspago(parameter));
      commit('SET_DATA_ESTATUS', await estatus(parameter));// estatus
      // commit('SET_DATA_CONDICION', await updateListaPrecio(parameter));// actualizar condición de venta
      commit('SET_DATA_USERCNR', await usercnr(parameter));// cnr
      // commit('SET_DATA_REGION', await usercnr(parameter));// cnr
    },

    toAssignVIA({ commit }, parameter) {
      commit('SET_SALESORDER', parameter);
    }, // tipo pedido
    toAssignACCOUNT({ commit }, payload) {
      commit('SET_ACCOUNT', payload);
    },
    toAssignTransporte({ commit }, parameter) {
      commit('SET_TRANSPORTE', parameter);
    },
    toAssignRegion({ commit }, parameter) {
      commit('SET_REGION', parameter);
    },
    toAssignCond_Compra({ commit }, parameter) {
      commit('SET_COND_COMPRA', parameter);
    },
    toAssignVP({ commit }, parameter) {
      commit('SET_VIASPAGO', parameter);
    },
    toAssignNotas({ commit }, parameter) {
      commit('SET_NOTA', parameter);
    },
    toAssignDescription({ commit }, parameter) {
      commit('SET_DESCRIPTION', parameter);
    },
    toAssignNEWFLETE({ commit }, parameter) {
      commit('SET_FLETE', parameter);
    },
    toAssignTOTALFLETE({ commit }, parameter) {
      commit('TOTALFLETE', parameter);
    },
    toAssignPoblacion({ commit }, parameter) {
      commit('SET_POBLACION', parameter);
    },
    toAssignCodigo({ commit }, parameter) {
      commit('SET_CODIGO', parameter);
    },
    toAssignNoSap({ commit }, parameter) {
      commit('SET_NR_SAP', parameter);
    },
    toAssignFlete({ commit }, parameter) {
      commit('SET_FLETE', parameter);
    },
    toAssignIVACHE({ commit }, parameter) {
      commit('SET_IVACHECK', parameter);
    },
    toAssignPORC({ commit }, parameter) {
      commit('SET_PORC', parameter);
    },
    toAssignDescuento({ commit }, parameter) {
      commit('SET_DESCUENTO', parameter);
    },
    toAssignCondicion({ commit }, parameter) {
      commit('SET_CONDICION', parameter);
    },
    toAssignSUCURSAL({ commit }, parameter) {
      commit('SET_SUCURSAL', parameter);
    },
    toAssignEJECUTIVO({ commit }, parameter) {
      commit('SET_EJECUTIVO', parameter);
    },
    toAssigntoAssignId({ commit }, parameter) {
      commit('SET_ID', parameter);
    },
    toAssigntoAssignCLIENTE_ESPORADICO({ commit }, parameter) {
      commit('SET_DATA_CLIENTE_ESPORADICO', parameter);
    },
    toAssignTaza({ commit }, parameter) {
      commit('SET_TASA', parameter);
    },
  },
};
